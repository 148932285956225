<template>
	<div>
		<v-card v-if="Optional == 0">
			<v-card-text>
				<b>Abastecimiento por QR</b>
			</v-card-text>
			
			<v-container>
				<v-row>
					<v-col cols="12">
						<s-scanner-qr
							:config="configScann"
							autofocus
							return-object
							@onValue="onValue($event)"
							@onData="onData($event)"
							:readonly="false"
							:clearInput="clearInput"
						></s-scanner-qr>
					</v-col>
					<!-- <v-col cols="12" xs="4" sm="4" md="4" >
						<s-switch 
							label="Abast. Manual" 
							v-model="Optional"
						></s-switch>
					</v-col> -->
					
				</v-row>
			</v-container>
		</v-card>

		<v-card v-else>
			<v-card-text>
				<b>Abastecimiento Manual</b>
			</v-card-text>
			
			<v-container>
				<v-row>
					<v-col cols="12">
						<s-text
							label="Código Parihuela"
						></s-text>
					</v-col>
					<v-col cols="12" lg="12" md="12">
						<v-btn 
							color="info" 
							@click="saveOptional()"
						>Grabar</v-btn>
					</v-col>
					<!-- <v-col cols="12" xs="4" sm="4" md="4" >
						<s-switch 
							label="Abast. Manual" 
							v-model="Optional"
						></s-switch>
					</v-col> -->
					
				</v-row>
			</v-container>
		</v-card>

		<v-card flat style="margin-top: -8px; margin-bottom: 5px;">
			<v-row style="margin: auto" justify="center">
				<v-col cols="3" lg="3" md="3">
					<s-select-definition
						hidden
						:def="1257"
						label="Calibradora"
						style=" border-radius: 8px;"
						v-model="TypeCalibrator"
					/>
				</v-col>
				<v-col cols="12" lg="4" md="4">
					<s-text
						label="N° Jabas"
						v-model="LcsAmountSupply"
						
						number
						autofocus
						
					/>
				</v-col>
				
			</v-row>
		</v-card>

		<s-crud
			title="Abastecimiento - Calibrado"
			:filter="filter"
			:config="config"
			@rowSelected="rowSelected($event)"
			ref="gridCalibratedSupply"
			height="auto"
			noFull
		>
			<template v-slot:Options="{ row }">
					<div style="display: flex;">
						<!-- <v-btn
							x-small
							:color="row.LcsID > 0 ? 'warning' : colorSuccess"
							:height="40"
							dark
							rounded
							shaped
							style="border-radius: 13px; margin: 10px 10px 10px 10px;"
							
							>{{
								row.LcsID > 0
									? "Terminar Abastecimiento"
									: "Abastecer Fruta: "
							}} -->
							<b>
								<v-chip style="margin: 0px" small :color="'error'">
									{{ AmountJabasWeigh }}
								</v-chip>

								<!-- @click="clickInitial(row)" + AmountJabasWeigh -->
							</b>
							<!-- <v-icon style="font-size: 16px !important">fas fa-eye</v-icon> -->
						<!-- </v-btn> -->
					</div>
				</template>
		</s-crud>
	</div>
</template>

<script>
	import _sLotProdCalibratedSupplyService from "../../../services/FreshProduction/PrfLotProdCalibratedSupplyService";

	export default {
		data() {
			return {
				clearInput : false,
				Optional: 0,
				TypeCalibrator: 0,
				TypeTub: 0,
				LcsAmountSupply: 0,
				AmountJabasWeigh: 0,
				filter: {
					cDateInitial: "",
					cDateFin: "",
					filterAll: 0,
					PrfID: 0,
				},
				config: {
					service: _sLotProdCalibratedSupplyService,
					model: {
						RcfID: "ID",
						Options: "",
						/* TypeCalibrator: "",
						TypeTub: "", */
						LcsAmountSupply: "",
					},

					headers: [
						{ text: "Por Abast.", value: "Options", width: 100 },
						/* { text: "Calibradora.", value: "TypeCalibrator", width: 150 },
						{ text: "Tina", value: "TypeTub", width: 150 }, */
						/* { text: "Cantidad", value: "LcsAmountSupply", width: 100 }, */
						{
							text: "N° Jabas Lote",
							value: "LcsAmountSupplyReal",
							width: 100,
						},
						{ text: "Nro. Lote.", value: "RcfID", width: 100 },
						{ text: "Tipo Servicio", value: "TypeServiceName", width: 100 },
						/* { text: "Productor", value: "PrdCardName", width: 260 }, */
						{ text: "Fundo", value: "FagName", width: 260 },
						{ text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
						{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
						{ text: "Variedad", value: "VrtName", width: 160 },
						{ text: "Procedencia", value: "TypeOriginName", width: 160 },
					],
				},
				configScann: {},
				WpbID: 0,
				numberPallet: 0,
				selected: {},
				RcfID: 0,
				colorSuccess: "success",
			}
		},

		watch: {
			Optional(newValue, oldValue) {
				console.log('this.optional ', this.Optional);
			},

			clearInput()
			{
				if(this.clearInput)
				{
					//this.clearInput = false;
				}
			}
		},

		methods: {
			rowSelected(val) {
				if (val.length > 0) {
					this.AmountJabasWeigh =
						val[0].AmountJabasWeigh - val[0].LcsAmountSupplyReal;
					this.selected = val[0];
					console.log("rowSelected", val);
					this.clickInitial(this.selected)
					//this.clearInput = false;
				}
			},

			/* saveOptional(){

				if(this.RcfID == 0){
					this.$fun.alert(
							"Ingrese N° Lote de Produccion",
							"warning",
						);
					return;
				}

				if (this.LcsAmountSupply <= 0) {
						this.$fun.alert(
							"La Cantidad a abastecer debe ser mayor a 0",
							"warning",
						);
						return;
				}



				let items = {}
				items.RcfID = this.RcfID
				items.UsrCreateID = this.$fun.getUserID();
				items.SecStatus = 1
				items.TypeCalibrator = this.TypeCalibrator;
				items.TypeReception = 2,
				items.TypeTub = 0;
				items.LcsAmountSupply = this.LcsAmountSupply;
				items.LcsFlag = 2 //ABASTECER MANUAL
				items.LcsState = 2;
				items.LcsHourInitial = this.$fun.getHour();
				items.LcsHourFinal = this.$fun.getHour();

				this.$fun.alert("Esta seguro de Abastecer al Lote Nro: ? " + this.RcfID, "question").then(val => {
					if (val.value) {
						_sLotProdCalibratedSupplyService
							.save(items, this.$fun.getUserID())
							.then(r => {
								if (r.status == 200) {
									this.$fun.alert(
										"Abastecido correctamente",
										"success",
									);

									this.LcsAmountSupply = 0
									
								}
							});
					}
				});


				console.log(items);
			},
 */
			clickInitial(items) {
				
				console.log("codigo", items);
				var message = "";
				items.UsrCreateID = this.$fun.getUserID();
				items.TypeCalibrator = this.TypeCalibrator;
				items.TypeReception = 2;
				items.TypeTub = 0;
				items.WpbID = this.WpbID;
				items.WpbNumberPallet = this.numberPallet;
				items.LcsAmountSupply = this.LcsAmountSupply;
				items.LcsFlag = 1 //ABASTECER POR QR

				if (items.LcsID == 0) {
					items.LcsState = 1;
					items.LcsHourInitial = this.$fun.getHour();

					var jabasFaltante =
						items.AmountJabasWeigh - items.LcsAmountSupplyReal;

					if (jabasFaltante < items.LcsAmountSupply) {
						this.$fun.alert(
							"LOTE ABASTECIDO EN SU TOTALIDAD (" +
								items.AmountJabasWeigh +
								") recepcionada. Fruta restante => (" +
								jabasFaltante +
								"). Comuniquese con el Area de Produccion.",
							"success",
						);
						return;
					}

					if (jabasFaltante == 0) {
						this.$fun.alert(
							"Ya no se puede abastecer saldo de Jabas es 0",
							"warning",
						);
						return;
					}

					if (items.LcsAmountSupply <= 0) {
						this.$fun.alert(
							"La Cantidad a abastecer debe ser mayor a 0",
							"warning",
						);
						return;
					}

					if (items.AmountJabasWeigh < items.LcsAmountSupply) {
						this.$fun.alert(
							"La Cantidad a abastecer supera la cantidad recepcionada en Frescos",
							"warning",
						);
						return;
					}
					/* if(this.TypeCalibrator !== this.selected.TypeCrop){
						this.$fun.alert(
							"La Calibrado seleccionada es diferente al Tipo de Cultivo de Lote de Producción",
							"warning",
						);
						return;
					} */

					message =
						"Esta seguro de Abastecer al Lote Nro: ? " + items.RcfID;

						/* console.log("items", items);
						this.$fun.alert(message, "question").then(val => {
							if (val.value) { */
								_sLotProdCalibratedSupplyService
									.save(items, this.$fun.getUserID())
									.then(r => {
										if (r.status == 200) {
											
											this.$fun.alert(
												"Actualizado correctamente",
												"success",
											);
											this.$refs.gridCalibratedSupply.refresh();
											this.colorSuccess = "error"
										}
									});
							/* }
						}); */

				} /* else {
					items.LcsState = 2;
					items.LcsHourFinal = this.$fun.getHour();
					message =
						"Esta seguro de terminar abastecimiento al Lote Nro: ? " +
						items.RcfID;
				} */

				

				console.log("Guardar", items);
			},

			onValue(val) {
				if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {
					console.log('hola mundo', val);
					this.filter.PrfID = val;
					this.colorSuccess = "success"
				}
				//this.clearInput = true;
			},

			onData(val) {
				if (val === undefined) {
					console.log("ingreso manual");
				} else {
					var res = val.split(",", 24);
					this.WpbID = parseInt(res[1]);
					this.LcsAmountSupply = parseInt(res[2]);
					console.log("fdsf");
					this.numberPallet = parseInt(res[3]);

					this.filter.WpbID = this.WpbID;
					this.filter.PrfID = parseInt(res[0]);

					console.log("onData", res);
					
				}
				//this.clearInput = true;
			},
		},
	}
</script>